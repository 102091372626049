/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "leaflet/dist/leaflet.css"
import wrapWithProvider from "./src/state/wrap-with-provider"

export const wrapRootElement = wrapWithProvider
