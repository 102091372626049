/* eslint-disable import/no-anonymous-default-export */
import { createStore, combineReducers, applyMiddleware } from "redux"
import { taskMiddleware } from "react-palm/tasks"
import keplerGlReducer from "kepler.gl/reducers"

const initialstate = {
  loadercomplete: true,
  docsversion: "Docs_1.2",
  activemenu: "introduction",
}
export const activeversion = value => ({
  type: "version",
  value,
})
export const activeitems = value => ({
  type: "activeitem",
  value,
})
export const TutorialExperienced = value => ({
  type: "Tutorial",
  value,
})
function reducer(state = initialstate, action) {
  switch (action.type) {
    case "version":
      return { ...state, docsversion: action.value }
    case "activeitem":
      return { ...state, activemenu: action.value }
    case "Tutorial":
      return { ...state, tutorial: action.value }
    default:
      return state
  }
}


const reducers = combineReducers({
  keplerGl: keplerGlReducer,
  reducer,
})

// preloadedState will be passed in by the plugin
export default preloadedState => {
  return createStore(reducers, preloadedState, applyMiddleware(taskMiddleware))
}
